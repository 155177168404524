@import "../../../../styles/colors";
@import "../../../../styles/mediaQueries";

.projects__grid-item {
  border-radius: 4px;
  display: flex;
  align-items: flex-end;
  padding: 24px;
  background-color: $light-color;
  box-shadow: -2px 2px 5px rgba($cta-color, 50);
  transition: 0.4s ease-in-out;
  cursor: pointer;

  &:hover {
    box-shadow: -8px 8px 0 $cta-color;

    .project__grid-item-content {
      margin: 0 0 6px 6px;
    }
  }

  &#projects__grid-item-1 {
    @include tablet {
      height: 560px;
    }
  }

  &#projects__grid-item-2 {
    @include tablet {
      height: 280px;
    }
  }

  &#projects__grid-item-3 {
    @include tablet {
      height: 440px;
    }
  }

  &#projects__grid-item-4 {
    @include tablet {
      height: 440px;
    }
  }

  &#projects__grid-item-5 {
    margin-top: 0;

    @include tablet {
      height: 400px;
      margin-top: -280px;
    }
  }

  .project__grid-item-content {
    transition: 0.4s ease-in-out;
    .project__grid-item-content__tag {
      margin: 8px 0 16px 0;
    }
  }
}
