@import "../../styles/colors";
@import "../../styles/baseStyles";

.detailed-project__main-container {
  .detailed-project__hero-container {
    background: $gradient-background;
    position: relative;
    padding: 80px 0 160px 0;

    &::after {
      content: "";
      position: absolute;
      left: -20px;
      width: 200%;
      height: 200px;
      z-index: 0;
      background-color: $light-color;
      bottom: -190px;
      transform: rotate(4deg);
    }

    .project-info__container {
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: 80px;

      .background__title {
        font-size: 18px;
        font-weight: 500;
      }

      .reading-time {
        margin-top: 24px;
      }

      .background__info {
        margin-top: 16px;
      }

      .background-container {
        margin-top: 24px;
      }
    }
  }

  .detailed-project__info {
    margin-top: 48px;
    position: relative;
    z-index: 1;
  }
}
