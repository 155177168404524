* {
  margin: 0;
  padding: 0;
  border: 0;
  margin-block-start: 0;
  margin-block-end: 0;
  padding-inline-start: 0;
}

a {
  color: inherit;
  text-decoration: none;
}

ol,
ul {
  list-style: none;
}
