@import "../../../styles/colors";
@import "../../../styles/mediaQueries";

.welcome__main-container {
  margin: 380px 0 80px 0;

  .welcome__content-container {
    display: grid;
    grid-template-columns: 1fr;
    gap: 20px;
    align-items: center;

    @include tablet {
      grid-template-columns: 1fr 1fr;
    }

    .headshot__container {
      margin-left: 0px;
      margin: 0 20px;

      @include tablet {
        margin-left: -60px;
      }

      img {
        width: 100%;
        max-width: 440px;
        height: auto;
      }
    }

    .welcome-text__container {
      margin: 16px 40px;

      h1 {
        margin: 8px 0;
      }

      .welcome-text__name {
        margin: 8px 0;
      }

      .welcome-text {
        margin: 16px 0;
      }

      .about-link {
        font-size: 18px;
        margin: 16px 0;
      }
    }
  }
}

.container-line {
  position: relative;

  &.texan {
    margin-top: 80px;
  }

  &::after {
    content: "";
    position: absolute;
    left: -40px;
    top: 50%;
    transform: translateY(-50%);
    height: calc(100% + 32px);
    width: 2px;
    background-color: $accent-color;
  }
}
