@import "../../styles/colors";
@import "../../styles/baseStyles";

.footer__container {
  margin-top: 120px;
  padding: 120px 0;
  background: $gradient-background;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}
