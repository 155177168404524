@import "../styles/colors";

$border-radius: 10px;
$box-shadow: 0 0 10px 0 rgba($dark-color, 0.3);
$gradient-background: linear-gradient(
  to left,
  $gradient-purple-color,
  $gradient-pink-color
);

:export {
  borderRadius: $border-radius;
  boxShadow: $box-shadow;
  gradientBackground: $gradient-background;
}
