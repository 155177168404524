@import "../../styles/colors";

.link {
  font-size: 18px;
  color: $cta-color;
  position: relative;
  transition: 0.3s ease-in-out;
  transition-delay: 0.1s;

  &::after {
    content: "";
    width: 100%;
    height: 1px;
    position: absolute;
    left: 50%;
    transform: translate(-50%);
    bottom: 0;
    background-color: $cta-color;
    transition: 0.3s ease-in-out;
    z-index: -1;
  }

  &:hover {
    color: $light-color;
    &::after {
      padding: 4px 8px;
      height: 100%;
      border-radius: 4px;
      bottom: 50%;
      left: 50%;
      transform: translate(-50%, 50%);
    }
  }

  &.active {
    color: $light-color;
    &::after {
      padding: 4px 8px;
      height: 100%;
      border-radius: 4px;
      bottom: 50%;
      left: 50%;
      transform: translate(-50%, 50%);
    }
  }
}

.link__container {
  width: fit-content;
  display: flex;
  align-items: center;
  transition: 0.3s ease-in-out;
  transition-delay: 0.1s;

  &:hover {
    .link {
      color: $light-color;
      &::after {
        padding: 4px 8px;
        height: 100%;
        width: calc(100% + 32px);
        border-radius: 4px;
        left: -8px;
        bottom: 50%;
        transform: translateY(50%);
      }
    }

    svg {
      .link-icon {
        stroke: $light-color;
      }
    }
  }
  .link {
    margin-right: 8px;
  }
}
