@import "../../../../styles/colors";
@import "../../../../styles/baseStyles";
@import "../../../../styles/mediaQueries";

.article-card__container {
  margin: 40px 0;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: auto auto;
  gap: 20px;

  @include tablet {
    grid-template-columns: 1fr 1fr;
    grid-template-rows: auto;
  }

  .card__info {
    grid-row: 2 / 3;
    @include tablet {
      grid-row: auto;
    }
  }

  .card-description {
    margin: 16px 0;
  }

  .card-link {
    display: flex;
    align-items: center;

    &:hover {
      svg {
        .link-icon {
          stroke: $light-color;
        }
      }
    }

    span {
      margin-right: 8px;
    }
  }

  .card-image__container {
    width: 100%;
    height: fit-content;
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    background: $gradient-background;
    transition: 0.4s ease-in-out;
    cursor: pointer;

    &:hover {
      margin: 0 0 8px 8px;
      box-shadow: -8px 8px 0 $cta-color;
    }

    .card-image {
      width: 100%;
      border-radius: 4px;
    }
  }
}
