@import "../../../styles/colors";
@import "../../../styles/baseStyles";

.hero__container {
  width: 100%;
  height: 65vh;
  background: $gradient-background;
  display: flex;
  align-items: center;
  position: relative;
  min-height: 400px;

  &::after {
    content: "";
    position: absolute;
    left: 50%;
    bottom: -150px;
    transform: translateX(-50%);
    width: 120%;
    height: 60vh;
    border-radius: 50%;
    background: inherit;
    z-index: -1;
  }

  .hero__cta {
    color: $cta-color;
    font-size: 80px;
    position: relative;
    z-index: 2;
  }
}

.illustration__container {
  width: 100%;
  height: 100vh;
  position: absolute;
  left: 0;
  bottom: -50%;
  z-index: 1;
}
