$light-color: #fff;
$dark-light-color: #d1d1d1;
$dark-medium-color: #555555;
$dark-color: #121212;
$gradient-pink-color: #ffd6d6;
$gradient-purple-color: #dde3f9;
$cta-color: #3458da;
$accent-color: #ef8989;

:export {
  lightColor: $light-color;
  darkLightColor: $dark-light-color;
  darkMediumColor: $dark-medium-color;
  darkColor: $dark-color;
  gradientPinkColor: $gradient-pink-color;
  gradientPurpleColor: $gradient-purple-color;
  ctaColor: $cta-color;
  accentColor: $accent-color;
}
