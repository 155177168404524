@import "../../../styles/colors";
@import "../../../styles/baseStyles";
@import "../../../styles/mediaQueries";

.projects__main-container {
  background: $gradient-background;
  position: relative;
  padding: 150px 0;

  &::before,
  &::after {
    content: "";
    position: absolute;
    left: -20px;
    width: 200%;
    height: 200px;
    z-index: 1;
    background-color: $light-color;
  }

  &::before {
    top: -10px;
    transform: rotate(4deg);
  }

  &::after {
    bottom: -140px;
    transform: rotate(4deg);
  }

  .projects__grid {
    padding-bottom: 80px;
    display: grid;
    grid-template-columns: 1fr;
    gap: 20px;

    @include tablet {
      grid-template-columns: 1fr 1fr 1fr;
    }
  }
}
