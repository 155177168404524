@import url("https://fonts.googleapis.com/css2?family=DM+Serif+Display:ital@0;1&family=Lexend+Deca:wght@100;200;300;400;500;600;700;800;900&display=swap");
@import "./reset";
@import "./mediaQueries";
@import "./colors";
@import "./baseStyles";

body {
  color: $dark-color;
  font-family: "Lexend Deca", -apple-system, BlinkMacSystemFont, "Segoe UI",
    Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
}

h1 {
  font-size: 80px;
}

h1,
h2 {
  font-family: "DM Serif Display";
  line-height: 88px;
}

h2,
h3 {
  color: $cta-color;
  font-weight: bold;
}

h2 {
  font-size: 64px;

  &.section-title {
    padding: 80px 0;
  }
}

h3 {
  font-size: 32px;
  line-height: 48px;
  font-weight: 900;
}

h4 {
  font-size: 24px;
}

p {
  color: $dark-color;
  font-size: 18px;
  font-weight: 200;
}

.info-text {
  color: #555555;
  font-size: 16px;
  font-weight: 200;
}

.light-weight {
  font-weight: 300;
}

.main__container {
  overflow: hidden;

  .page__content {
    margin-top: 70.5px;
  }

  .max-width__container {
    width: 100%;
    max-width: 940px;
    margin: 0 auto;
  }

  .gutters {
    margin: 0 40px;
  }
}
