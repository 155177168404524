$mobile: 767px;
$tablet: 768px;
$smallScreen: 900px;
$desktop: 1440px;

@mixin mobile {
  @media (max-width: #{$mobile}) {
    @content;
  }
}

@mixin tablet {
  @media (min-width: #{$tablet}) {
    @content;
  }
}

@mixin smallScreen {
  @media (min-width: #{$smallScreen}) {
    @content;
  }
}

@mixin desktop {
  @media (min-width: #{$desktop}) {
    @content;
  }
}
