.marshmallow-project__info-container {
  .why__image-grid-container {
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    grid-template-areas:
      "image-1 image-1 image-1 image-1 image-1 image-2 image-2 image-2 image-2 image-3 image-3 image-3"
      "image-4 image-4 image-4 image-5 image-5 image-5 image-5 image-6 image-6 image-6 image-6 image-6";
    gap: 20px;
    width: 100%;

    .why-image {
      width: 100%;
      height: auto;
    }

    #why__image-1 {
      grid-area: image-1;
    }
    #why__image-2 {
      grid-area: image-2;
    }
    #why__image-3 {
      grid-area: image-3;
      align-self: flex-end;
    }
    #why__image-4 {
      grid-area: image-4;
    }
    #why__image-5 {
      grid-area: image-5;
    }
    #why__image-6 {
      grid-area: image-6;
    }
  }
}
