@import "../../styles/colors";
@import "../../styles/baseStyles";

.navbar__container {
  width: 100%;
  padding: 24px 0;
  background: $gradient-background;
  display: flex;
  align-items: center;
  position: fixed;
  z-index: 3;
  transition: 0.3s ease-in-out;

  &.box-shadow {
    box-shadow: 0 1px 4px rgba($cta-color, 0.5);
  }

  ul {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .navbar__links {
      display: flex;
      align-items: center;

      .nav__link:not(:last-child) {
        margin-right: 32px;
      }
    }
  }
}
